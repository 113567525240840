// Multi-step code

.rl-multi-step {
    margin: ($base-margin / 2) 0;
}

// Setting up flexbox for list
.rl-multi-step-list {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    .rl-multi-step-item:first-child { margin-left: 0; }
    .rl-multi-step-item:last-child { margin-right: 0; }
}

// Defaults for each 'step'
.rl-multi-step-item {
    cursor: default;
    position: relative;
    width: 100%;
    margin: 0 ($base-margin / 6);
    @media only screen and (max-width: $screen-xs-max) {
        margin: 0 ($base-margin / 6);
    }
    z-index: 2;
    border-radius: $base-border-radius;

    // Step title and subtitle defaults
    .rl-item-title,
    .rl-item-subtitle {
        cursor: default;
        position: relative;
        margin: 0;
        z-index: 2;
    }
    @media only screen and (max-width: $screen-xs-max) {
        .rl-item-subtitle { display: none; }
    }
    .rl-item-title {
        color: $upq-blue;
        font-weight: 600;
        margin: 0;
    }

    // Different step states [ active, current, completed, error]
    &.active:hover { cursor: default;/*pointer;*/ }
    &.current .rl-item-title,
    &.current .rl-item-subtitle {
        cursor: default;
        color: $text-color-inverted;
    }
    &.active.current:hover .rl-item-title,
    &.active.current:hover .rl-item-subtitle {
        color: $upq-blue;
    }
    &.error:after {
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        right: .5em;
        font-family: FontAwesome;
        content: $icon-danger;
        color: $brand-danger;
    }
}

// Creates the 'arrow' effect / background colors
.rl-item-wrap {
    padding: $base-padding;
    position: relative;
    height: 100%;
    margin-left: 3px;
    &:before, &:after {
        position: absolute;
        left: 0;
        content: ' ';
        width: 100%;
        height: 50.5%;
        z-index: 1;
        //background-color: $gray-lighter;
        background-image: linear-gradient(to left, $gray-lightest, $gray-lighter);
        background-repeat: no-repeat;
    }

    // Top of the arrow
    &:before {
        top: 0;
        transform: skew(20deg);
        border-radius: .2em .2em 0 0;
    }
    // Bottom of the arrow
    &:after {
        bottom: 0.05px;
        transform: skew(-20deg);
        border-radius: 0 0 .2em .2em;
    }
}

// Changing arrow colors based on state
.current .rl-item-wrap:before,
.current .rl-item-wrap:after {
    background-color: $upq-blue;
    background-image: none;
}

.active:hover .rl-item-wrap:before,
.active:hover .rl-item-wrap:after {
    background-color: $clickable-hover;
    background-image: none;
}

.rl-multi-step-item.error {
    .rl-item-title,
    .rl-item-subtitle {
        padding-right: ($base-padding * 2);
    }
}

// Changing step styles based on :first/:last step
.rl-multi-step-item:first-child .rl-item-wrap,
.rl-multi-step-item:last-child .rl-item-wrap {
    width: 100%;
    border-radius: $base-border-radius;
    &:before, &:after { width: 50%; }
}

// If first step, only point on the right
.rl-multi-step-item:first-child .rl-item-wrap {
    background: linear-gradient(to right, $gray-lighter 95%, transparent 5%);
    &:before, &:after { left: 50%; }
}

.active.rl-multi-step-item:first-child:hover .rl-item-wrap {
    background: linear-gradient(to right, $clickable-hover 95%, transparent 5%);
}

.current.rl-multi-step-item:first-child .rl-item-wrap {
    background: linear-gradient(to right, $upq-blue 95%, transparent 5%);
}

// If last step, only indent on the left
.rl-multi-step-item:last-child .rl-item-wrap {
    background: linear-gradient(to left, $gray-lighter 0, $gray-lightest 0);
    &:before, &:after { right: 50%; }
}

.active.rl-multi-step-item:last-child:hover .rl-item-wrap {
    background: linear-gradient(to left, $clickable-hover 95%, transparent 5%);
}

.current.rl-multi-step-item:last-child .rl-item-wrap {
    background: linear-gradient(to left, $upq-blue 95%, transparent 5%);
}

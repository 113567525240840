#sidebar {
    width: 290px;
    position: fixed;
    top: 0;
    left: -290px;
    height: 100vh;
    z-index: 999;
    background: $upq-blue;
    color: #fff;
    transition: all 0.3s;
    overflow-y: auto;
}

#sidebar.active {
    left: 0;
}

.overlay {
    display: none;
    position: fixed;
    /* full screen */
    width: 100vw;
    height: 100vh;
    /* transparent black */
    background: rgba(0, 0, 0, 0.7);
    /* middle layer, i.e. appears below the sidebar */
    z-index: 998;
    opacity: 0;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
}

/* display .overlay when it has the .active class */
.overlay.active {
    display: block;
    opacity: 1;
    top: 0;
}

#dismiss {
    width: 35px;
    height: 35px;
    //position: absolute;
    ///* top right corner of the sidebar */
    //top: 10px;
    //right: 10px;
}

.upq-main-nav-cat-dropdown {
    //position: fixed !important;
    width: 90vw;
    max-height: 60vh;
    min-height: 120px;
    margin: 0 auto;
    left: 5% !important;
    right: 5% !important;
    top: auto !important;
}

.show>.dropdown-menu:not(.user-nav) {
    left: 50%;
    width:80vw;
    transform: translateX(-50%);
}

.w-md-50 {
    width: 50% !important;
}

@media (max-width: 767.98px) {

    .w-md-50 {
        width: 100% !important;
    }
}

.upq-main-nav-li{
    position: unset !important;
}

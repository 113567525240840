
// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
//@import url('https://fonts.googleapis.com/css?family=Roboto');
//@import url('https://fonts.googleapis.com/css?family=RobotoSlab');

// Variables
@import 'variables';

//Components
@import 'checkout_nav';
@import 'tooltips';
@import 'custom-dropdown';
@import 'carousel';
@import 'navbar_toggle';
//@import 'sticky_footer';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap');

body {
    font-family: Roboto;
}

$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 3
);

@each $name, $breakpoint in $grid-breakpoints {
	@media (min-width: $breakpoint) {
		.card-deck .card.home-cat {
			flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
		}
	}
}


/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.full-height {
    height: 100vh;
}

.max-w-1500 {
    max-width: 1500px;
}

.max-w-230 {
    max-width: 230px;
}
/*
Calculates content full height for viewport by removing the height of the top nav and footer.
 */
.calc-full-height {
    min-height: calc(100vh - 483px);
}

body,
.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-control:focus {
    box-shadow: none;
}

.form-control-underlined {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
}

.m-h-150 {
    min-height: 150px;
}

.width-content {
    width: fit-content;
}

.font-roboto-slab {
    font-family: "Roboto Slab", "Roboto", sans-serif;
}

.font-roboto-slab-bold {
    font-family: "Roboto Slab", "Roboto", sans-serif;
    font-weight: bold;
}

.font-roboto-medium {
    font-family: "Roboto";
    font-weight: normal;
}

.font-40 {
    font-size: 40px;
}

.font-20 {
    font-size: 20px;
}

.font-30 {
    font-size: 30px;
}

.line-height-1 {
    line-height: 1.3em;
}

.px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.btn.btn-primary {
    background-color: $upq-blue;
    border-color: $upq-blue;
}

.border-upq-blue {
  border-color: $upq-blue !important;
}

a {
    text-decoration: none !important;
}

a:not(.nav-link) :not(.card) :not(.card-title) :not(.accordion-title) :not(.text-upq-yellow) {
    color: $upq-orange;
}

.bg-black {
    background-color: #000000;
}

.bg-upq-blue {
    background-color: $upq-blue;
}

.bg-upq-yellow {
    background-color: $promo-yellow;
    border-color: transparent!important;
}

.bg-photo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-photo-0{
    background-color: rgb(255, 255, 255);
    background:
        linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.7)
        ),
        url("/images/ruchindra-gunasekara-GK8x_XCcDZg-unsplash-min.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-photo-1{
    background-color: rgb(0, 116, 176);
    background:
        linear-gradient(
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.5)
        ),
        url('/images/boxes_bg-min.png');
    //background-image: url("/images/boxes_bg-min.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-photo-2{
    min-height: 300px;
    background-color: #ffffff;
    background-image: url("/images/packaging_bg-min.png");
    background-position: top right;
}

.text-black {
    color: #000000 !important;
}

.text-white {
    color: #ffffff !important;
}

.text-qyellow {
    color: $promo-yellow;
}

.text-upq-blue {
    color: $upq-blue;
}

.text-upq-orange {
    color: $upq-orange !important;
}
.text-warning {
    color: #FCD900!important;
}

.invert{
    -webkit-filter: invert(100%);
}

.logo {
    width: 153px;
    height: 45px;
}

.social-icon {
    width: 17px;
}

.ss-icon {
    width: 41px;
}

.cart-icon {
    width: 28.6px;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.navbar-nav {
    li.active {
        background-color: #034277;
    }
}

.upq-qty-input {
    width: 60px;
}

.upq-add-bale-btn {
    line-height: 1.6em;
}

.img-thumbnail {
    max-width: 120px;
    max-height: 120px;
}

.d-content {
    display: contents;
}

.empty-dot {
    width: 12px;
    height: 12px;
    display: inline-block !important;
    padding: unset;
}

.product-detail-img {
    max-height: 400px;
}

.upq-featured-department-card {
    height: 160px;
}

.upq-featured-department-card img{
    width: auto;
    height: 80px;
}

.upq-child-department-img {
    width: 100px;
    height: auto;
}

.upq-department-feature-img{
    width: 100%;
    overflow: hidden;
    min-height: 220px;
}

.a-basic,
.a-basic:hover,
.a-basic:focus,
.a-basic:active{
    color: #000;
    text-decoration: none;
}

.btn.basic:hover,
.btn.basic:focus,
.btn.basic:active {
    box-shadow: none;
}

.accordion a.collapsed i{
    transform: rotate(0deg);
}

.accordion a i{
    transform: rotate(180deg);
}

.cursor-pointer {
    cursor: pointer;
}

.nav-link.active {
    background: $black;
    color: $promo-yellow;
    width: fit-content;
}

.cart-flash {
    background-color: $white;
    border-color: $upq-blue;
    border-radius: 0;
    color: $black;
}

td {
    width: 20%;
}

.mobile-available {
    display: -webkit-inline-box;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.table thead th {
    vertical-align: top;
}

.primary-address-checkbox-flag {
    margin-right: auto;
    width: 38px;
}

.imgFrame {
    min-height: 400px;
    display: flex;
    flex-direction: row;
}

.imgFrame img {
    max-width: 350px;
    max-height: 350px;
    align-self: center;
}

.btn-lg.btn-special {
    font-size: 1rem;
    padding: 1rem 3rem;
    text-transform: uppercase; 
}

#topBar {
    ul.navbar-nav.left  {
        li.nav-item {
            padding-right: 25px;
        }
    }
    ul.navbar-nav.right  {
        li.nav-item {
            padding-left: 25px;
        }
    }
    .upq-cart-badge {
        span {
            color: #ffffff!important;
            vertical-align: middle;
        }
        i.fa-shopping-cart {
            color: #ffffff!important;
            font-size: initial;
        }
    }
}

#homeCover {
    height: 319px;
    background-image: url("/images/warehouse.png");
    font-family: 'Nunito', sans-serif;
    .overlayBg {
        background-color: rgba(0, 54, 99, 0.71);
        width: 100%;
        height: 100%;
    }
    h1 {
        font-weight: 800;
    }
    h2 {
        font-weight: 700;
    }
}

#homeDepartments {
    .card {
        margin-top: 15px;
        margin-bottom: 15px;
        img {
            max-height: 175px;
        }
        h4 {
            font-family: 'Nunito', sans-serif;
        }
    }
}
#homeTrustedBy {
    .card {
        img {
            opacity: 0.29;
        }
    }
}

.bigTitle {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 2em;
    color: #737373;
}

.prefooter {
    font-family: 'Nunito', sans-serif;
    h4 {
        color: #000;
        font-weight: 700;
        span {
            color: #1D68A7;
        }
    }
}

.taxExtemptBtn {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

#categoryDetail,
#productDetail {
    font-family: 'Nunito', sans-serif;
    .summary {
        h1 {   
            font-size: 35px; 
            font-weight: 700;
        }
        .features {
            .feature {
                display: inline-flex;
                align-items: center;
                font-weight: 700;
                img {
                    max-height: 55px;
                }
            }
        }
    } 
}

#productDetail {
    .summary {
        ul {
            list-style: none;
            li:before {
                content: '\f058';
                font-family: fontawesome;
                float: left;
                margin-left: -1.5em;
                color: #FCD900;
            }
        }
        em {
            color: #707070;
            font-size: 90%;
        }
    }
}

#custom-search-input {
    ::placeholder {
        color: black;
        opacity: 1;
    }
    :-ms-input-placeholder {
        color: black;
    }
    ::-ms-input-placeholder { 
        color: black;
    }
    a.btn {
        text-transform: uppercase;
        border-radius: 0;
    }
    input[type=search] {
        border-radius: 0;
    }
}

.productsList {
    font-family: 'Nunito', sans-serif;
    th {
        color: #FCD900;
    }
    th.colImage,
    td.colImage {
        width: 30px!important;
    }
    td {
        vertical-align: middle;
    }
    .productImage {
        height: 80px;
        width: 80px;
        border: 1px solid black;
        max-width: 80px;
        max-height: 80px;
        display: inline-flex;
        justify-content: center;
        vertical-align: middle;
        background-color: #e9eaec;
        &:hover {
            border: 1px solid transparent;
        }
        img {
            max-width: 78px;
            max-height: 78px;
        }
    }
    a.productName {
        color: black;
        text-decoration: underline!important;
        font-weight: 600;
        &:hover {
            text-decoration: none!important;
        }
    }
}

.productPage {
    font-family: 'Nunito', sans-serif;
    h2 {
        font-weight: bold;
    }
    .imgFrame {
        img {
            align-self: flex-start;
        }
    }
    .productWrapPrices {
        min-width: 270px;
        display: inline-block;
        .productPrices {
            margin-right: 10px;
            .table-sm {
                td {
                    padding: 0.15rem;
                }
            }
        }
        .productActions {
            display: flex;
        }
    }
    .imgFrame {
        min-height: 300px;
    }
}

#categoryGrid {
    font-family: 'Nunito', sans-serif;
    width: 100%;
    h3 {
        min-width: 300px;
        font-size: 22px;
        display: inline-block;
        border-bottom: 12px solid #FCD900;
        line-height: 2px;
        font-weight: 700;
        padding-left: 3px;
    }
    .card {
        .card-img {
            min-height: 300px;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        .card-title {
            font-size: 18px;
        }
        img {
            max-height: 300px;
        }
        .badge {
            width: 70px;
            height: 70px;
            border-radius: 35px;
            line-height: 64px;
            font-size: 18px;
            vertical-align: middle;
            position: absolute;
            right: -25px;
            top: -25px;
        }
        .badge.close-out {
            line-height: normal;
            padding-top: 18px;
            font-size: 14px;
            white-space: normal;
        }
    }    
}

.page-item.active {
    .page-link {
        border-color: #1D68A7;
        background-color: #1D68A7;
    }
}

.page-link {
    color: black;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
}

.footer {
    background-color: #00437C;
}

//Tablets..
@media (min-width: 768px) and (max-width: 992px){
    .small-for-tablet {
        font-size: 80%;
        font-weight: 400;
        text-transform: uppercase;
    }
}

@media (min-width: 768px) {

    .h-md-100 {
        height: 100%;
    }

    .border-md-right {
        border-right: 1px solid;
    }
    // table.productsList thead tr th:first-child,
    // table.productsList tbody tr td:first-child {
    //     width: 18em;
    //     min-width: 18em;
    //     max-width: 18em;
    // }
    table.productsList .skuCol,
    table.productsList .dimensionsCol,
    table.productsList .qtyCol,
    table.productsList .bundlePriceCol {
        text-align: center;
    }
}

//SMALL/mobile SCREENS
@media (max-width: 767.98px) {

    .font-sm-30 {
        font-size: 30px;
    }

    .font-sm-20 {
        font-size: 20px;
    }

    .logo {
        width: 110px;
        height: auto;
    }

    .table.stackable th, .table.stackable td {
        border-top: 0;
    }

    .table.stackable td:not(.mobile-available) {
        width: 100%;
        display: block;
    }

    .table.stackable thead:not(.tiny-table-head) {
        display: none;
    }

    .upq-department-feature-img {
        min-height: 25vw;
    }

    .nav-link.active {
        width: 100%;
    }

    .productPage {
        .productWrapPrices {
            display: block;
            margin-bottom: 25px;
        }
        .imgFrame {
            min-height: unset;
        }
    }

    .productsList {
        border-collapse: separate;
        border-spacing: 0 3em;
        margin-top: -3em;
        .productNameCol {
            padding-left: 0.75rem!important;
        }

        .actionsCol {
            text-align: left!important;
            padding-left: 0.75rem!important;
        }
    }

}